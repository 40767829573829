import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { faLink } from '@fortawesome/pro-solid-svg-icons';
import Dropdown from './DropdownV2';
import ConfirmModal from '../Modal/ConfirmModal';
import useCRUD from '../../_Hooks/useCRUD';
import { DropdownContainer, StyledMenu } from './DropdownV2.styled';
import CopyLinkButton from '../Button/CopyLinkButton';
import FileUploadButton from '../Button/FileUploadButton';
import ItemDetailButton from '../Button/ItemDetailButton';
import DropDownButton from '../Button/DropDownButton';
import { specificationDropDown, viewAttachments } from '../../lib/mapping/Dropdown/specificationDropdown';
import EditModalFactory from '../EditModalFactory';
import CatalogDrawer from '../Drawer/CatalogDrawer';
import { spaces } from '../../styles/style';
import ConfirmModalAlertInfoBuilder from '../Modal/ConfirmModalAlertInfoBuilder';
import { getCatalogDrawerDisplayToast } from '../../lib/helpers/helper';

const SpecificationDropDown = ({
  item,
  idReference,
  referenceKey = 'idRefurbish',
  isCustomer,
  isCustomerView,
  afterSubmit,
  refetchOptions,
  mappingKey = 'specificationItem',
  model = 'refurbish-items',
  noPadding = false,
  renderColumns = {},
  modalParams = {}
}) => {
  const { refurbishItemType } = useSelector(state => state.setup.enums);

  const [showModal, setShowModal] = useState();

  const { handleCreate, handleUpdate, handleDelete } = useCRUD({
    model,
    immediatelyLoadData: false
  });

  const { handleGet: getLibraryItem } = useCRUD({
    model: 'item',
    immediatelyLoadData: false
  });

  const actions = {
    post: handleCreate,
    put: handleUpdate
  };
  const optionsKey = {
    post: 'postOptions',
    put: 'updateOptions'
  };

  const handleCrudClick = (verb, params) => () => {
    actions[verb]({ ...params, id: item?.id, values: item, refresh: false, [optionsKey[verb]]: refetchOptions }).then(
      resp => {
        if (!resp?.error && afterSubmit) {
          afterSubmit(item);
        }
      }
    );
  };

  const handleClick = ({ modal, params }) => () => {
    setShowModal({ modal, params });
  };
  const isComposition = item?.type === refurbishItemType.composition;
  const isSinapi = item?.item?.source === 'SINAPI';

  const custom = {
    uploadFile: (
      <FileUploadButton
        id="add-attachment"
        model={model}
        referenceKey={referenceKey}
        idReference={idReference}
        item={item}
        isGetById
        afterSubmit={afterSubmit}
      >
        Adicionar anexo
      </FileUploadButton>
    ),
    copyLink: (
      <CopyLinkButton onClose={f => f} eventButton="item" urlContext="o" idData={item?.id} text>
        Copiar link
      </CopyLinkButton>
    )
  };

  const modal = {
    confirm: params => (
      <ConfirmModal
        onSubmit={() =>
          handleDelete({ id: item?.id, displayToast: 'Operação realizada com sucesso!', refresh: false }).then(resp => {
            if (!resp?.error && afterSubmit) afterSubmit(item, 'delete');
          })
        }
        onClose={() => setShowModal(null)}
        {...params}
      />
    ),
    editItem: params => (
      <EditModalFactory
        type={item?.type}
        idReference={idReference}
        id={item?.id}
        model={model}
        renderColumns={renderColumns}
        onClose={_item => {
          setShowModal(null);
          afterSubmit(_item);
        }}
        {...params}
      />
    ),
    unlinkItem: params => (
      <ConfirmModal
        onSubmit={() =>
          handleUpdate({
            id: item?.id,
            displayToast: getCatalogDrawerDisplayToast(null),
            values: { idItem: null, externalBaseCode: null, event: 'item-nature-changed', action: 'detached' },
            refresh: false
          }).then(() => afterSubmit(item))
        }
        onClose={() => setShowModal(null)}
        title="Desvincular item"
        alertInfo="Ao desvincular esse item, ele perderá a relação com a Biblioteca."
        preInfoDescription="Deseja concluir ação?"
        alertInfoPadding={`${spaces.space1} ${spaces.space1}`}
        {...params}
      />
    ),
    updateItem: params => (
      <ConfirmModal
        onSubmit={() => {
          if (!item?.idItem) return;

          getLibraryItem({
            refetchPathOptions: `/${item.idItem}`,
            refetchOptions: {
              attributes: ['price', 'idSupplier', 'idRefurbishGroup', 'idCostCenter', 'brand', 'link', 'description']
            }
          }).then(resp => {
            if (!resp) return;
            handleUpdate({
              updatePathOptions: `/${item?.id}/setChildrenFromItem`,
              displayToast: 'Operação realizada com sucesso!',
              values: { ...resp, idItem: item.idItem, externalBaseCode: item.externalBaseCode },
              refresh: false
            }).then(() => afterSubmit(item));
          });
        }}
        onClose={() => setShowModal(null)}
        title="Atualizar item"
        alertInfo={
          <ConfirmModalAlertInfoBuilder
            alertText={`Ao atualizar esse item, os seguintes campos serão
          substituídos pelas informações do item na ${isSinapi ? 'SINAPI' : 'Biblioteca'}:`}
            afterAlertList={[
              'Custo',
              'Fornecedor',
              'Marca',
              'BDI',
              'Grupo',
              'Categoria',
              'Link',
              'Descrição',
              ...(isComposition ? ['Itens dentro da composição'] : [])
            ]}
          />
        }
        preInfoDescription="Deseja atualizar as informações do item?"
        alertInfoPadding={`${spaces.space1} ${spaces.space1}`}
        {...params}
      />
    ),
    linkItem: params => (
      <CatalogDrawer
        shouldConfirm
        onSubmit={catalogItem => {
          return handleUpdate({
            updatePathOptions: `/${item?.id}/setChildrenFromItem`,
            displayToast: getCatalogDrawerDisplayToast(catalogItem),
            values: {
              idItem: catalogItem?.id,
              externalBaseCode: catalogItem?.externalBaseCode,
              event: 'item-nature-changed',
              action: 'attached',
              ...(!isComposition && { type: catalogItem?.type })
            },
            refresh: false
          }).then(() => {
            setShowModal(null);
            afterSubmit(item);
          });
        }}
        onClose={() => setShowModal(null)}
        open={showModal}
        cardProps={{ addTooltipText: 'Vincular', addIcon: faLink, iconStyle: { margin: '-3.5px' } }}
        disableTypeFilter
        tabsToOpen={[0, 1]}
        fixedType={isComposition ? refurbishItemType.composition : refurbishItemType.productLabor}
        {...modalParams}
        {...params}
      />
    )
  };

  const renderMappingKey = typeof mappingKey === 'object' ? mappingKey : specificationDropDown[mappingKey];

  const menu = (
    <StyledMenu $alignLeftOptions={false}>
      {renderMappingKey?.map(option => (
        <React.Fragment key={`menu${option.label}`}>
          <Menu.Item>
            {custom[option.button] || (
              <DropDownButton
                optionData={option}
                id={option.id}
                text
                onClick={
                  option.verb && !option.modal ? handleCrudClick(option.verb, option.params) : handleClick(option)
                }
                {...option?.buttonParams}
              />
            )}
          </Menu.Item>
        </React.Fragment>
      ))}
    </StyledMenu>
  );

  return (
    <>
      {showModal && modal[showModal.modal] ? modal[showModal.modal](showModal.params) : null}
      <DropdownContainer noPadding={noPadding}>
        <ItemDetailButton
          show={!!item.link}
          icon="faLink"
          onClick={() => {
            window.open(item.link);
          }}
        />
        <ItemDetailButton
          show={!!item?.files?.length > 0 || item?.countFile > 0}
          icon="faPaperclip"
          titleTooltip="Ver anexos"
          onClick={() => setShowModal(viewAttachments)}
        />
        {!isCustomer && !isCustomerView ? (
          <Dropdown slim trigger={['click']} menu={menu} padding={0} placement="bottomLeft" />
        ) : null}
      </DropdownContainer>
    </>
  );
};

SpecificationDropDown.propTypes = {
  item: PropTypes.instanceOf(Object),
  referenceKey: PropTypes.string,
  idReference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  idParent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isCustomer: PropTypes.bool,
  isCustomerView: PropTypes.bool,
  afterSubmit: PropTypes.func,
  mappingKey: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  model: PropTypes.string,
  refetchOptions: PropTypes.instanceOf(Object),
  noPadding: PropTypes.bool,
  renderColumns: PropTypes.instanceOf(Object),
  modalParams: PropTypes.instanceOf(Object)
};

export default SpecificationDropDown;
